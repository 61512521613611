import React from 'react'
import { Layout, Stack, Main, Sidebar, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Pagination from '@components/Pagination'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Heading, Text } from 'theme-ui'
import CookieConsent, { Cookies } from 'react-cookie-consent'

const Posts = ({
  data: { featuredPosts = {}, paginatedPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {}, basePath } = {} } = props

  return (
    <Layout {...props}>
      <CookieConsent
        enableDeclineButton
          location="bottom"
          buttonText="Aceptar"
          declineButtonText="Declinar"
          cookieName="gatsby-gdpr-google-tagmanager"
          onAccept={() => {
            Cookies.set('gatsby-gdpr-google-tagmanager', 'true');
            window.location.reload();
          }}
          onDecline={() => {
            Cookies.set('gatsby-gdpr-google-tagmanager', 'false');
            window.location.reload();
          }}>
        Este sitio utiliza cookies para mejorar la experiencia de usuario.
        </CookieConsent>
      <Seo title='Home' />
      {/* {props.location.pathname === basePath && (
        <>
          <Divider />
          <Stack
            effectProps={{ effect: false }}
            title='Lo más visto'
            direction={[`column`, `column`, `column`, `row`]}
          >
            <Main mb={[3, 3, 3, 0]}>
              <CardList
                nodes={featuredPosts.nodes}
                limit={1}
                variant={['vertical', 'horizontal-lg']}
                loading='eager'
              />
            </Main>
            <Sidebar
              pl={[0, 0, 0, 3]}
              sx={{ display: [`block`, `block`, `flex`] }}
            >
              <CardList
                nodes={featuredPosts.nodes}
                limit={4}
                skip={4}
                variant={[
                  'horizontal-md',
                  'horizontal',
                  'horizontal',
                  'horizontal-aside'
                ]}
                omitMedia
                omitCategory
                aside
              />
            </Sidebar>
          </Stack>
          <Divider />
          <Stack>
            <Main>
              <CardList
                nodes={featuredPosts.nodes}
                limit={3}
                skip={1}
                columns={[1, 1, 1, 3]}
                variant={[
                  'horizontal-md',
                  'horizontal',
                  'horizontal',
                  'vertical'
                ]}
              />
            </Main>
          </Stack>
        </>
      )} */}
      <Divider />
      <Stack>
        <Main>
          <Heading variant='h1' as='h1'>
            Galería de fotografías
          </Heading>
          <Text variant='p'>
            Suele decirse que 'una imágen vale más que mil palabras'. En esta
            sección de nuestra web podrás inspirarte con fotografías de muebles
            a medida terminados y colocados por nuestros carpinteros
            profesionales.
          </Text>
          <Divider />
          <CardList
            nodes={paginatedPosts.nodes}
            variant={['horizontal', 'vertical']}
            columns={[1, 2, 3, 3]}
          />
          <Divider />
          <Text variant='p'>
            ¿Necesitas un mueble a medida en Zaragoza que has visto en una
            fotografía en internet? No te preocupes, contacta con nosotros y te
            ayudaremos a realizar ese armario empotrado, estantería, mesa o lo
            que sea, siempre que sea en madera.
          </Text>
          <Text variant='p'>
            ¿Prefieres venir a visitarnos? Contacta con nosotros por teléfono y
            te atenderemos en nuestra carpintería de Zaragoza.
          </Text>
          <Text variant='p'>
            Confía en verdaderos profesionales en la fabricación y diseño de
            muebles a medida en Zaragoza con más de 30 años de experiencia y las
            herramientas más punteras.
          </Text>
        </Main>
      </Stack>
      {/* <Divider />
      <PreFooter> */}
      {/* <Pagination {...paginatedPosts.pageInfo} basePath={basePath} /> */}
      {/* {services.mailchimp && (
          <>
            <Divider />
            <NewsletterExpanded simple />
          </>
        )} */}
      {/* </PreFooter> */}
    </Layout>
  )
}

export default Posts
